<template>
  <div>
    <div v-for="(point, i) in filteredHistory" :key="i">
      <b-card>
        <b-row align-v="center">
          <b-col>
            <b-badge variant="light" class="mr-1 px-2">
              {{ point.event_type.toUpperCase() }}
            </b-badge>
          </b-col>
          <b-col cols="auto">
            <small class="font-weight-bold text-dark">{{ formatDate(point.created_at) }}</small>
          </b-col>
        </b-row>

        <div v-if="point.event_type === 'activated'">
          <hr />
          <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          User with email <b>{{ point.user.email }}</b> activated the giftcard
        </div>

        <div v-if="point.event_type === 'booking_flow'">
          <hr />
            <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          User with email <b>{{ point.user.email }}</b> entered the booking flow
        </div>

        <div v-if="point.event_type === 'booked'">
          <hr />
            <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          User with email <b>{{ point.user.email }}</b> booked the giftcard
        </div>

        <div v-if="point.event_type === 'customized' && point.column === 'custom_complete'">
          <hr />
            <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          Customized digital greeting
        </div>

        <div v-if="point.event_type === 'customized' && point.column === 'custom_pdf_complete'">
          <hr />
            <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          Customized pdf greeting
        </div>

        <div v-if="point.event_type === 'extended'">
          <hr />
            <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          The voucher's valid to date was changed from {{ point.from }} to {{ point.to }}
        </div>

        <div v-if="point.event_type === 'redeemed_review_mail_scheduled'">
          <hr />
            <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          Giftcard was redeemed and review mail scheduled at {{ point.to }}
          <b-button @click.prevent="triggerGiftcardEvent(point.event_type)" variant="default" size="sm"
            >Trigger mail now</b-button
          >
        </div>

        <div v-if="point.event_type === 'redeemed_review_mail_sent'">
          <hr />
            <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          Giftcard review mail sent at {{ point.to }}
        </div>

        <div v-if="point.event_type === 'redeemed_review_reminder_mail_scheduled'">
          <hr />
            <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          Giftcard was redeemed and review mail reminder scheduled at {{ point.to }}
        </div>

        <div v-if="point.event_type === 'redeemed_review_reminder_mail_sent'">
          <hr />
            <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          Giftcard review reminder mail sent at {{ point.to }}
        </div>

        <div v-if="point.event_type === 'redeemed' && point.to !== null">
          <hr />
            <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          Giftcard was redeemed on {{ formatDate(giftcard.redeemed_at) }} and
          {{
            giftcard.experienced_at
              ? `experienced on ${formatDate(giftcard.experienced_at)}.`
              : 'is yet to be experienced.'
          }} CSV code: <strong>{{ csvCode }}</strong>. Booked by: <strong> {{ point.user?.email }}</strong>
        </div>

        <div v-if="point.event_type === 'reverted' && point.to === null">
          <hr />
            <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          Giftcard was reverted on {{ formatDate(point.created_at) }}
        </div>

        <div v-if="point.event_type === 'reverted' && point.to !== null">
          <hr />
            <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          Reverted {{ giftcard.code }};
          {{ point.to.productName }} ({{ formatMoney({ amount: point.to.productValue, currency: 'NOK' }) }});
          {{ point.to.codeCount }} count;
          <br />
          <br />
          by: <strong> {{ point.user?.name }}</strong>
        </div>

        <div v-if="point.event_type === 'invoiced'">
          <hr />
            <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          Invoiced <a :href="point.invoiced.pdf" download>#{{ point.invoiced.id }}</a> on
          {{ formatDate(point.invoiced.created_at) }}
        </div>

        <div v-if="point.event_type === 'combined' && point.column !== 'json'">
          <hr />
            <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          Giftcard was combined into giftcard with code
          <strong>{{ point.to }}</strong>
        </div>

        <div v-if="point.event_type === 'combined' && point.column === 'json'">
          <hr />
            <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          Following giftcards were combined into giftcard with code <strong>{{ point.to.newCode }}</strong>:
          <ul>
            <li v-for="(transaction, i) in point.to.transactions" :key="i">
              {{ formatMoney(transaction.sum) }} from giftcard {{ transaction.code }}
            </li>
          </ul>
        </div>

        <div v-if="point.event_type === 'exchanged'">
          <hr />
            <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          Exchanged for giftcard with code <strong>{{ point?.exchanged?.code }}</strong>
          <span v-if="giftcard.combined_to"
            ><br />Exchanged giftcard was combined into giftcard with code
            <strong>{{ giftcard.combined_to }}</strong></span
          >
        </div>

        <div v-if="point.event_type === 'csv_booked'">
          <hr />
            <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          Exchanged for CSV with code <strong>{{ point.to }}</strong>
        </div>

        <div v-if="point.event_type === 'purchased'">
          <hr />
            <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          <div v-if="point.giftcard_sum > 0">
            {{ point.giftcard_sum }} kr from the giftcard was used in order #{{ point.to }}
          </div>
        </div>

        <div v-if="point.event_type === 'not_used'">
            <hr />
            <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
            Giftcard marked as <strong>not used</strong>.
        </div>

        <div v-if="point.event_type === 'manual_cash_voucher'">
          <hr />
            <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          Manual Cash voucher {{ giftcard.code }} ({{ formatMoney({ amount: point.to, currency: 'NOK' }) }})
          valid to date {{ formatDate(giftcard.valid_to) }}.
          <br />
          <br />
          by: <strong> {{ point.user?.name }}</strong>
        </div>

        <div v-if="point.event_type === 'order_created'">
          <hr />
            <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          Giftcard created {{ giftcard.code }}; {{ point.to.codeCount }} count;
          {{ point.to.productName }} ({{ formatMoney(point.to.productValue) }});
          Total ({{ formatMoney(point.to.totalValue) }})
          <br />
          Order payment method <strong>{{ point.to.paymentMethod }}</strong>;<br />
          Cash voucher: {{ formatMoney(point.to.paidByCashVoucher) }};<br />
          Payment gateway: {{ formatMoney(point.to.paidByPaymentGateway) }}
        </div>

        <div v-if="point.event_type === 'split'">
          <hr />
            <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          Split {{ point.to.oldCode }};
          {{ point.to.productName }} ({{ formatMoney({ amount: point.to.productValue, currency: 'NOK' }) }});
          {{ point.to.codeCount }} count
          <br />
          Created {{ point.to.newCodes.join(', ') }}
          <br />
          <br />
          by: <strong> {{ point.user?.name }}</strong>
        </div>

        <div v-if="point.event_type === 'added_to_cart'">
          <hr />
            <div v-if="point.open_giftcard" class="text-danger">
                <strong>OPEN GIFTCARD</strong>
                <span v-if="point.open_giftcard_code">
                    <strong>- {{ point.open_giftcard_code }}</strong>
                </span>
                <br>
            </div>
          Giftcard value {{ formatMoney(point.to.sumUsedInCart) }} is in cart;
          <br />
          Giftcard value left: {{ formatMoney(point.to.sumLeftOnGiftcard) }}
          <br />
            <div v-for="(order, key) in point.orders" :key="key">
                Giftcard value used {{ (order.amount / 100).toFixed(2) }} kr for Order ID: <strong>{{ key }}.</strong>
            </div>
        </div>

        <div v-if="point.event_type === 'transfered_to_another_cart'">
          <hr />
            <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          Giftcard value {{ formatMoney(point.to.sumInOtherCarts) }} was transfered to another cart;
          <br />
          Giftcard value before transfer: {{ formatMoney(point.to.newGiftcardSum) }}
        </div>

        <div v-if="point.event_type === 'reverted_from_cart'">
          <hr />
          <span v-if="point.open_giftcard" class="text-danger"><strong>OPEN GIFTCARD</strong><br></span>
          Giftcard value {{ formatMoney(point.to.sumInOtherCarts) }} was reverted back to giftcard
          <br />
          Giftcard value after revert: {{ formatMoney(point.to.newGiftcardSum) }}
        </div>

      </b-card>
      <hr v-if="i !== filteredHistory.length - 1" />
    </div>
  </div>
</template>

<script>
import { giftcard } from '@/api'
import { formatDate, formatMoney } from '../page-helpers'

export default {
  name: 'GiftcardHistory',
  props: { giftcard },
  data() {
    return { history: [] }
  },
  computed: {
    filteredHistory() {
      return this.history.filter(history => this.shouldDisplayEvent(history.event_type, history.column))
    },
    csvCode() {
      return this.filteredHistory.find((point) => point.event_type === 'csv_booked')?.to;
    }
  },
  mounted() {
    this.loadContent()
  },
  methods: {
    formatDate,
    formatMoney,
    async loadContent() {
      const response = await giftcard.transactions(this.giftcard.id)
      this.history = response.data()
    },
    shouldDisplayEvent(event, typeOfStatus) {
      return (
        !['sum', 'full_sum', 'customized'].includes(event) ||
        ['custom_pdf_complete', 'custom_complete'].includes(typeOfStatus)
      )
    },
    async triggerGiftcardEvent(event) {
      await giftcard.triggerEvent(this.giftcard.id, event).then(() =>
        this.$bvToast.toast(event + ' successfully triggered.', {
          title: 'Event triggered',
          variant: 'primary',
        })
      )
    },
  },
}
</script>
